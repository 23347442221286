export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>Come with us to record your memories with Cantona LLC</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-01.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>{" "}
                    </div>
                    <img src="img/portfolio/team-01.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-02.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>{" "}
                    </div>
                    <img src="img/portfolio/team-02.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-03.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>{" "}
                    </div>
                    <img src="img/portfolio/team-03.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-04.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>{" "}
                    </div>
                    <img src="img/portfolio/team-04.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-05.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>{" "}
                    </div>
                    <img src="img/portfolio/team-05.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-06.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>{" "}
                    </div>
                    <img src="img/portfolio/team-06.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-07.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>
                    </div>
                    <img src="img/portfolio/team-07.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-08.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>
                    </div>
                    <img src="img/portfolio/team-08.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-4">
              <div className="portfolio-item">
                <div className="hover-bg">
                  {" "}
                  <a href="img/portfolio/team-10.png" title="Project Title" data-lightbox-gallery="gallery1">
                    <div className="hover-text">
                      <h4>zoom</h4>
                    </div>
                    <img src="img/portfolio/team-10.png" className="img-responsive" alt="Project Title" />{" "}
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
